import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './index.scss'
import Switch from "react-switch";
import moment from 'moment';
import PbxDetails from '../pbxDetails/pbxDetails.js';
import PowerTable, { PowerTableColumn } from '../PowerTable/PowerTable.js';

import { _loc } from '../../i18n/i18n.js';


import { VscLoading, VscSync, VscSearch, VscTerminalPowershell } from 'react-icons/vsc';
import { GoBrowser } from 'react-icons/go';
import { PiPlugs, PiPlugsConnected } from 'react-icons/pi';
import { MdOutlineQueryStats } from 'react-icons/md';
import { IoWarningSharp } from 'react-icons/io5';

class PbxTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxDerniereVue: 120,
      maxServiceAgreementDays: 7,
      loading: true,
      showConnected: true,
      showDisconnected: true,
      showAlarmsOnly: false,
      showDiskErrorOnly: false,
      ipbxs: [],
      lastUpdate: 0,
      filter: '',
      settings: null,
      serialDetails: null,
      directSSH: null,
      hiddenColumns: [],
    }

    this.timerLoading = null;
    this.timerReload = null;
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.timerLoading = setTimeout(async () => {
      await this.getSettingsAsync();
      await this.getIPBXsAsync();
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timerLoading);
    clearTimeout(this.timerReload);
  }

  async getSettingsAsync() {
    const { api } = this.props;
    const settings = await api.settings.getSettingsAsync();

    this.setState({
      settings
    });
  }

  async getIPBXsAsync(forceLoading = false) {
    clearTimeout(this.timerReload);
    if (forceLoading) {
      await this.setLoadingAsync(true);
    }
    const { api } = this.props;
    const ipbxs = await api.ipbxs.getIPBXsAsync();

    this.setState({
      loading: false,
      ipbxs: ipbxs ? this.calculateAlarms(ipbxs) : [],
      lastUpdate: moment().unix()
    }, () => {
      clearTimeout(this.timerReload); //as we are asynchronous, maybe we will add this timer while another has been set. 
      this.timerReload = setTimeout(() => { this.getIPBXsAsync() }, 10000);
    });
  }

  setLoadingAsync(loading) {
    return new Promise((resolve) => {
      this.setState({
        loading
      }, () => {
        resolve();
      })
    });
  }

  setFilter(f) {
    this.setState({
      filter: f.toLowerCase()
    });
  }

  showStats(serial) {
    this.setState({
      serialDetails: serial
    });
  }

  hideStats(reload = false) {
    this.setState({
      serialDetails: null
    });
    if (reload) {
      this.getIPBXsAsync(true);
    }
  }


  prepareSSHTab(port) {
    this.setState({
      directSSH: {
        port: port,
        pwd: null
      }
    });
    /*
    */
  }

  openDirectSSHTab(withPutty) {
    const { directSSH } = this.state;
    const { pwd, port } = directSSH;
    const rserver = window.location.hostname !== "localhost" ? `${window.location.hostname}` : '192.168.11.215';
    if (withPutty) {
      window.open(`ssh://root@${rserver}:${port}`, "_self");
    }
    else {
      var base64pwd = btoa(pwd);
      window.open(`http://${rserver}:8888/?hostname=${rserver}&port=${port}&username=root&password=${base64pwd}&command=if [ "$(screen -ls | grep .radmin | wc -l)" = "0" ]; then screen -S radmin; else screen -x radmin; fi`, '_blank');
    }
    this.setState({
      directSSH: null
    });
  }


  calculateAlarms(ipbxs) {
    const { maxDerniereVue, maxServiceAgreementDays, settings } = this.state;
    var now = moment().unix();

    if (ipbxs === null) {
      return null;
    }

    //Calcule les alarmes
    const ipbxsWithAlarm = ipbxs.map((i) => {
      var ipbx = {
        ...i
      };
      ipbx.alarm = false;
      ipbx.diskErrors = false;
      ipbx.alarmTypes = [];
      if (now - ipbx.lastStat > maxDerniereVue) { //un ipbx qui n'a pas parlé depuis 2 minute est en alarme
        ipbx.alarm = true;
        ipbx.alarmTypes.push("laststat");
      }
      if (ipbx.stats !== null) {
        if (ipbx.stats.cpuusage > settings.alarmCpu) {
          ipbx.alarm = true;
          ipbx.alarmTypes.push("loadavg");
        }
        if (ipbx.stats.ramusage > settings.alarmMemory) {
          ipbx.alarm = true;
          ipbx.alarmTypes.push("memory");
        }
        if (ipbx.stats.diskusage > settings.alarmDisk) {
          ipbx.alarm = true;
          ipbx.alarmTypes.push("disk");
        }
        if (ipbx.stats.diskerrors > 0 && settings.alarmDiskError) {
          ipbx.alarm = true;
          ipbx.diskErrors = true;
          ipbx.alarmTypes.push("diskerrors");
        }
      }
      if (ipbx.invalidlicenses > 0) {
        ipbx.alarm = true;
        ipbx.alarmTypes.push("licenses");
      }
      if (ipbx.backups && ipbx.backups.length > 0) {
        const lbckp = ipbx.backups[ipbx.backups.length - 1];
        if (settings.alarmBackup && moment().subtract("2", "w").unix() > lbckp.creationDate) {
          ipbx.alarm = true;
          ipbx.alarmTypes.push("lastBackup");
        }
      }
      else if (settings.alarmBackup) {
        ipbx.alarm = true;
        ipbx.alarmTypes.push("lastBackup");
      }
      if (settings.alarmServiceAgreement && now - ipbx.serviceAgreementValidity > maxServiceAgreementDays * 86400) {
        //ipbx.alarm = true; //Not an alarm but we want to display it
        ipbx.alarmTypes.push("serviceagreement");
      }
      return ipbx;
    });
    return ipbxsWithAlarm;
  }


  filterBeforeRender(ipbxs) {
    const { showConnected, showDisconnected, showAlarmsOnly, showDiskErrorOnly, filter } = this.state;
    if (ipbxs === null) {
      return null;
    }
    return ipbxs.filter((i) => {
      //si on demande de ne pas afficher les IPBX déconnectés, et que l'IPBX est déconnecté, on affiche pas
      if (!showDisconnected && !i.connected) {
        return false;
      }
      //si on ne demande pas les IPBX connectés, et que l'IPBX est connecté, on affiche pas
      if (!showConnected && i.connected) {
        return false;
      }
      //si on demande à afficher que les alarms, et que l'IPBX n'est pas en alarme (alarme réelle) on affiche pas
      if (showAlarmsOnly && (i.alarmsEnabled && i.unresolvedAlarmCount === 0)) {
        return false;
      }
      //si on demande à afficher que les alarmes disque et que l'IPBX n'en a pas, on affiche pas
      if (showDiskErrorOnly && i.diskErrors === false) {
        return false;
      }
      //si le filtre est vierge, on affiche
      if (filter === "") {
        return true;
      }
      //si le nom, serial, ou notes contient le filtre, on affiche
      if (
        i.name.toLowerCase().indexOf(filter) > -1
        || i.serial.toLowerCase().indexOf(filter) > -1
        || i.notes.toLowerCase().indexOf(filter) > -1
        // || i.model.toLowerCase().indexOf(filter) > -1
        || i.version.toLowerCase().indexOf(filter) > -1
      ) {
        return true;
      }
      //par défaut on affiche pas
      return false;
    });
  }




  render() {
    const { api, hasBackupOption } = this.props;
    const { ipbxs, loading, showConnected, showDisconnected,
      showAlarmsOnly, showDiskErrorOnly, filter, serialDetails, lastUpdate, directSSH,
      hiddenColumns } = this.state;

    const rserver = window.location.hostname !== "localhost" ? `${window.location.hostname}` : '192.168.11.215';

    const visibleIPBXs = this.filterBeforeRender(ipbxs);
    const ipbxsWithDiskErrors = ipbxs.filter(i => i.diskErrors);

    return (
      <div className='pbxtable'>
        <h1>{_loc("myXorcomPBX")}</h1>
        <div className='filter'>
          <div className='filter-boxes'>
            <div className='checkbox'>
              <Switch height={18} width={40} borderRadius={3} checked={showConnected} onChange={(e) => { this.setState({ showConnected: e }) }} />
              <label>{_loc("showConnected")}</label>
            </div>
            <div className='checkbox'>
              <Switch height={18} width={40} borderRadius={3} checked={showDisconnected} onChange={(e) => { this.setState({ showDisconnected: e }) }} />
              <label>{_loc("showDisconnected")}</label>
            </div>
            <div className='checkbox'>
              <Switch height={18} width={40} borderRadius={3} checked={showAlarmsOnly} onChange={(e) => { this.setState({ showAlarmsOnly: e }) }} />
              <label>{_loc("showInAlarmOnly")}</label>
            </div>
            <div className='checkbox'>
              <Switch height={18} width={40} borderRadius={3} checked={showDiskErrorOnly} onChange={(e) => { this.setState({ showDiskErrorOnly: e }) }} />
              <label>{_loc("showInDiskErrorOnly")}</label>
            </div>
          </div>
          <div className='filter-text'>
            <div className='input-icon'>
              <VscSearch />
              <input type="text" value={filter} onChange={(e) => { this.setFilter(e.currentTarget.value) }} />
            </div>
          </div>
        </div>

        {
          ipbxsWithDiskErrors.length > 0
            ? <p className='mt-1 mb-1 has-disk-errors'>{_loc("diskerrorsalert")}</p>
            : null
        }

        <div className='table-menu'>
          <div className='buttons'>
            <button className='bg-info' onClick={() => { this.getIPBXsAsync(true) }}><VscSync /> {_loc("reload")}</button>
            <small>
              {_loc("thereAre", visibleIPBXs !== null ? visibleIPBXs.length : 0, moment.unix(lastUpdate).format('HH:mm:ss'))}
            </small>
          </div>
        </div>



        <PowerTable
          id="powertable-pbxs"
          saveSettings={true}
          hiddenColumns={hiddenColumns}
          columns={[
            new PowerTableColumn("serial", "serial", _loc("serial"), {
              hideable: false
            },),

            new PowerTableColumn("name", "name", _loc("pbxName"), { sort: true },),

            new PowerTableColumn("sshport", "sshport", _loc("sshPort"), {
              width: 120,
              hideable: false
            }, (obj, value) => {
              return obj.connected
                ? <button className='link' onClick={() => { this.prepareSSHTab(value) }}><VscTerminalPowershell /> {value}</button>
                : <span className='disabled'><VscTerminalPowershell /> {value}</span>
            }),

            new PowerTableColumn("webport", "webport", _loc("webPort"), {
              width: 120,
              hideable: false,
            }, (obj, value) => {
              return obj.connected
                ? <a href={`http://${rserver}:${value}`} rel="noreferrer" target='_blank' ><GoBrowser /> {value}</a>
                : <span className='disabled'><GoBrowser /> {value}</span>
            }),

            new PowerTableColumn("connected", "connected", _loc("status"), {
              width: 62,
            }, (obj, value) => {
              return <Fragment>
                {obj.connected ? <PiPlugsConnected className='text-success' /> : <PiPlugs className='text-danger' />}
                {obj.alarm ? <IoWarningSharp className='text-danger alarm' /> : null}
              </Fragment>
            }),

            new PowerTableColumn("lastStat", "lastStat", _loc("lastStat"), {
              width: 120,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("laststat") > -1 ? 'text-danger bold' : ''}>{value > 1 ? moment.unix(value).fromNow() : _loc("never")}</span>
            }),

            // new PowerTableColumn("model", "model", _loc("model"), {
            //   width: 92
            // },),

            new PowerTableColumn("version", (col, obj) => {
              const versionArray = obj.version.split('.');
              while (versionArray.length < 4) {
                versionArray.push(0);
              }
              const versionNumber = (versionArray[0] << 24) + (versionArray[1] << 16) + (versionArray[2] << 8) + versionArray[3];
              return versionNumber
            }, _loc("version"), {
              width: 70
            }, (obj, value) => { return obj.version }),

            new PowerTableColumn("cpuusage", (col, obj) => { return obj.stats !== null ? obj.stats.cpuusage : '' }, _loc("cpuLoad"), {
              width: 50,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("loadavg") > -1 ? 'text-danger bold' : ''}>{value ? `${value} %` : ''}</span>
            }),

            new PowerTableColumn("ramusage", (col, obj) => { return obj.stats !== null ? obj.stats.ramusage : '' }, _loc("memory"), {
              width: 50,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("memory") > -1 ? 'text-danger bold' : ''}>{value ? `${value} %` : ''}</span>
            }),

            new PowerTableColumn("diskusage", (col, obj) => { return obj.stats !== null ? obj.stats.diskusage : '' }, _loc("disk"), {
              width: 50,
            }, (obj, value) => {
              return <Fragment>
                {
                  obj.diskErrors
                    ? <span className='disk-errors' title={_loc("diskerrors")}><IoWarningSharp className='text-danger alarm' /></span>
                    : null
                }
                <span className={obj.alarmTypes.indexOf("disk") > -1 ? 'text-danger bold' : ''}>{ }</span>
                {value ? `${value} %` : ''}
              </Fragment>
            }),

            new PowerTableColumn("lastBackup", (col, obj) => {
              return obj.backups && obj.backups.length > 0
                ? obj.backups[obj.backups.length - 1].creationDate
                : 0
            }, _loc("lastBackup"), {
              width: 100,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("lastBackup") > -1 ? 'text-danger bold' : ''}>
                {
                  value > 0
                    ? moment.unix(value).format("DD/MM/YYYY")
                    : "Never"}
              </span>
            }),

            new PowerTableColumn("backupsSize", "backupsSize", _loc("backupsSize"), {
              width: 100,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("backupsSize") > -1 ? 'text-danger bold' : ''}>{obj.backupsSizeStr}</span>
            }),

            new PowerTableColumn("serviceAgreementValidity", "serviceAgreementValidity", _loc("serviceAgreement"), {
              width: 120,
            }, (obj, value) => {
              return <span className={obj.alarmTypes.indexOf("serviceagreement") > -1 ? 'text-danger bold' : ''}>{value > 0 ? moment.unix(value).format("DD/MM/YYYY") : ''}</span>
            }),

            new PowerTableColumn("invalidLicenses", (col, obj) => { return obj.stats !== null ? obj.stats.invalidlicenses : '' }, _loc("invalidLicenses"), {
              width: 120,
            }, (obj, value) => {
              return <span className={value > 0 ? 'text-danger bold' : ''}>{`${value}`}</span>
            }),

            new PowerTableColumn("unresolvedAlarmCount", "unresolvedAlarmCount", _loc("unresolvedAlarms"), {
              width: 67,
            }, (obj, value) => {
              return <span className={obj.alarmsEnabled && obj.unresolvedAlarmCount > 0 ? 'text-danger bold' : ''}>{obj.alarmsEnabled ? obj.unresolvedAlarmCount : '-'}</span>
            }),

            new PowerTableColumn("details", null, _loc("details"), {
              width: 80,
              hideable: false,
            }, (obj, value) => {
              return <div className='see-details' onClick={() => { this.showStats(obj.serial) }}><MdOutlineQueryStats />{_loc("see")}</div>
            }),
          ]}
          data={visibleIPBXs}
        />




        {
          serialDetails !== null
            ? <PbxDetails api={api} serial={serialDetails} hasBackupOption={hasBackupOption} onBack={(reload = true) => { this.hideStats(reload); }} />
            : null
        }



        {
          directSSH !== null
            ? <div className='directssh'>
              <div className='directssh-outer'></div>
              <div className='directssh-inner'>
                <h3>{_loc("connectToPort", directSSH.port)}</h3>
                <div>
                  <h4>{_loc("connectViaPutty")}</h4>
                  <div className='buttons'>
                    <button class='bg-success' onClick={() => { this.openDirectSSHTab(true) }}>{_loc("openWithPutty")}</button>
                  </div>
                </div>
                <div className='mt-3'>
                  <h4>{_loc("openWithWebconsole")}</h4>
                  <div className='form'>
                    <div className='form-row'>
                      <label>{_loc("rootPassword")}</label>
                      <input type="password" autocomplete="new-password" value={directSSH.pwd !== null ? directSSH.pwd : ""}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && directSSH.pwd !== null && directSSH.pwd !== "") {
                            e.preventDefault();
                            setTimeout(() => {
                              this.openDirectSSHTab(false)
                            }, 100);
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ directSSH: { ...directSSH, pwd: e.currentTarget.value } })
                        }} />
                    </div>
                  </div>
                  <div className='buttons'>
                    <button class='bg-success' disabled={directSSH.pwd === null || directSSH.pwd === ""} onClick={() => { this.openDirectSSHTab(false) }}>{_loc("connect")}</button>
                    <button class='bg-warning' onClick={() => { this.setState({ directSSH: null }) }}>{_loc("cancel")}</button>
                  </div>
                  <small className='bg-info'>{_loc("screen")}</small>
                </div>
              </div>
            </div>
            : null
        }

        {
          loading
            ? <div className='loading'>
              <VscLoading />
              {_loc("loading")}
            </div>
            : null
        }
      </div>
    );
  }
}

export default PbxTable;
PbxTable.propTypes = {
  api: PropTypes.object.isRequired,
  hasBackupOption: PropTypes.bool.isRequired
}

